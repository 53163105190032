.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

h1{
  font-family: 'Courier New', Courier, monospace;
}

#drum-machine{
 display: flex;
 justify-content: space-around;
  width: 500px;
  height: 200px;
  border: 1px solid #ccc;

  #buttons{
    width: 180px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .drum-pad{
      width: 50px;
      height: 50px;
      border: 1px solid #ccc;
      text-align: center;
      line-height: 50px;
      margin:4px;
      cursor:pointer;
    }
  }

  #controller{
    display: flex;
    flex-direction: column;
    justify-content: center;
    #display{
      width: 100px;
      height: 50px;
      line-height: 50px;
      background-color: #E0DFDF;
    }
  }

  
}
#footer{
  margin: 5px;
}